import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import photo_1 from '../../assets/img/photo_1.jpg'
import company_1 from '../../assets/img/company_1.jpg'
import company_2 from '../../assets/img/company_2.jpg'
import company_3 from '../../assets/img/company_3.jpg'
import FsLightbox from 'fslightbox-react';
import Separator from '../common/Separator';

const events = [
    // {
    //     id: 'women',
    //     name: 'Startup Weekend Women Edition',
    //     location: 'Tashkent, Uzbekistan',
    //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quam ducimus odio repudiandae modi ut asperiores molestiae nostrum aspernatur quisquam.',
    //     url: 'https://startupweekend.uz/',
    //     day: '19',
    //     month: 'May',
    //     year: '2023',
    //     disabled: true,
    // },
    {
        id: 'bukhara',
        name: 'Startup Weekend Bukhara',
        location: 'Bukhara, Uzbekistan',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quam ducimus odio repudiandae modi ut asperiores molestiae nostrum aspernatur quisquam.',
        url: 'https://bukhara.startupweekend.uz/',
        day: '18',
        month: 'Aug',
        year: '2023',
        disabled: true,
    },
    {
        id: 'qarshi',
        name: 'Startup Weekend Qarshi',
        location: 'Qarshi, Uzbekistan',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quam ducimus odio repudiandae modi ut asperiores molestiae nostrum aspernatur quisquam.',
        url: 'https://qarshi.startupweekend.uz/',
        day: '6',
        month: 'Oct',
        year: '2023',
    },
]

function UpcomingSection() {
    const ref = useRef();
    const [imageViewToggle, setImageViewToggle] = useState(false);

    const { t } = useTranslation();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.utils.toArray(".fullscreen_ribbon.normal").forEach(target => {
                gsap.to(target, {
                    scrollTrigger: {
                        trigger: target,
                        // markers: true,
                        scrub: 0.5,
                    },
                    keyframes: [
                        {
                            translateX: "-20%",
                            translateY: "30px",
                        },
                    ],
                    ease: "none",
                });
            });

            gsap.utils.toArray(".fullscreen_ribbon.reverse").forEach(target => {
                gsap.set(target, { translateX: "-10%" })
                gsap.to(target, {
                    scrollTrigger: {
                        trigger: target,
                        // markers: true,
                        scrub: 0.5,
                    },
                    keyframes: [
                        {
                            translateX: "0",
                            translateY: "30px",
                        },
                    ],
                    ease: "none",
                });
            });

            gsap.from(".counter", {
                scrollTrigger: {
                    trigger: ".counter",
                },
                textContent: 0,
                duration: 2,
                increment: 1,
                snap: { textContent: 1 },
            });

            gsap.utils.toArray(".brief_container").forEach(target => {
                gsap.set(target, { opacity: 0 })
                gsap.to(target, {
                    scrollTrigger: {
                        trigger: target,
                        start: "center bottom",
                        end: "center center+=10%",
                        // markers: true,
                        scrub: 0.5,
                    },
                    keyframes: [
                        {
                            opacity: 1
                        },
                    ],
                    ease: "none",
                });
            });
        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <>
            <section style={{ marginTop: "60vh" }} ref={ref}>
                <div className="container">
                    <h3 style={{ marginBottom: 70 }}>{t("our_upcoming_events")}</h3>
                    <div className='events_container'>
                        {
                            events.map((event) => (
                                <div className={`event_item ${event.disabled ? 'disabled' : ''}`}>
                                    <div className='event_date'>
                                        <div className='event_date-day'>{event.day}</div>
                                        <div className='event_date-month'>{event.month}</div>
                                        <div className='event_date-year'>{event.year}</div>
                                    </div>
                                    <div>
                                        <h4>{event.name}</h4>
                                        <span>{event.location}</span>
                                        <p>
                                            {event.description}
                                        </p>
                                    </div>
                                    <div className='event_action'>
                                        <a href={event.url} className='btn purple' target='_blank'>Learn more</a>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default UpcomingSection