import React, { useEffect } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import FooterCanvas from '../FooterCanvas/FooterCanvas';
import { UilInstagram, UilTelegram, UilLinkedin } from '@iconscout/react-unicons'

function FooterSection() {
    const ref = useRef();

    const { t } = useTranslation();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(".footer_container_wrapper", {
                scrollTrigger: {
                    trigger: ".footer_container_wrapper",
                    // markers: true,
                    start: "top bottom+=50%",
                    end: "bottom bottom",
                    scrub: true,
                },
                ease: "none",
                clipPath: "ellipse(70% 65% at 48% 66%)"
            })
        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <>
            <section className='footer_section' style={{ marginTop: "50vh" }} ref={ref}>
                <div className='footer_container_wrapper'>
                    <FooterCanvas />
                    <div className="container wide">
                        <div className='footer_content'>
                            <div>
                                <h3>{t("follow_us_on_social_media")}</h3>
                                <ul className='social_list'>
                                    <li><UilInstagram size={40} /><a href="#">@startupweekend.uz</a></li>
                                    <li><UilTelegram size={40} /><a href="#">@swtashkent</a></li>
                                    <li><UilLinkedin size={40} /><a href="#">swtashkent</a></li>
                                </ul>
                            </div>
                            <div></div>
                            <div className='credits'>
                                <h4 style={{ textAlign: "right" }}>Website by <br /> Sarvar Mutalov</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FooterSection