import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

function TransitionCircle() {
    const ref = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {

            window.addEventListener('langChange', () => {
                gsap.to(".transition-circle", {
                    keyframes: [
                        {
                            clipPath: 'circle(200% at 100% 0%)',
                            duration: 0.5
                        },
                        {
                            clipPath: 'circle(200% at 0% 100%)',
                            duration: 0.3,
                        },
                        {
                            clipPath: 'circle(0% at 0% 100%)',
                            duration: 0.5,
                        },
                    ],
                });
                gsap.set(".transition-circle", {
                    clipPath: 'circle(0% at 100% 0%)',
                });
            })

        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <div className="transition-circle"></div>
    )
}

export default TransitionCircle