import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { SlowMo } from "gsap/all";
import { useTranslation } from 'react-i18next';

function MissionSection() {
    const ref = useRef();

    const { t } = useTranslation();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {

            gsap.utils.toArray(".spaced_title").forEach(target => {
                gsap.to(target, {
                    scrollTrigger: {
                        trigger: target,
                        // markers: true,
                        start: "center-=100px 85%",
                        end: "center 15%",
                        scrub: 0.5,
                    },
                    keyframes: [
                        {
                            autoAlpha: 1,
                            scaleX: 1
                        },
                        {
                            autoAlpha: 1,
                            scaleX: 1,
                            duration: 0.5
                        },
                        {
                            autoAlpha: 0,
                            // scale: 0.92
                        },
                    ],
                    ease: "none",
                });
            });



        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <section ref={ref}>
            <div className="container">
                <h3 className="spaced_title">
                    {t("mission_1")}
                </h3>
                <h3 className="spaced_title">
                    {t("mission_2")}
                </h3>
                <h3 className="spaced_title">
                    {t("mission_3")}
                </h3>
            </div>
        </section>
    )
}

export default MissionSection