import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import light_bulb from '../../assets/img/emoji/light_bulb.webp'
import check_mark from '../../assets/img/emoji/check_mark.webp'
import microphone from '../../assets/img/emoji/microphone.webp'
import { UilAngleRightB, UilAngleDoubleDown } from '@iconscout/react-unicons'

function ProcessSection() {
    const ref = useRef();
    const path = useRef();

    const { t } = useTranslation();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const sections = gsap.utils.toArray(".process_panel");
            // const pathLength = document.querySelector(".process_path svg path").getTotalLength();
            // console.log(pathLength)

            const scrollTween = gsap.to(sections, {
                xPercent: -100 * (sections.length - 1),
                ease: "none", // <-- IMPORTANT!
                scrollTrigger: {
                    trigger: ".process_container",
                    pin: true,
                    scrub: 0.1,
                    //snap: directionalSnap(1 / (sections.length - 1)),
                    end: `+=${window.innerHeight * (sections.length - 1) + 1000}`,
                },
            });

            gsap.set(".process_container", {
                opacity: 0
            });
            gsap.to(".process_container", {
                scrollTrigger: {
                    trigger: ".process_container",
                    start: "center bottom",
                    end: "center center",
                    scrub: true,
                },
                keyframes: [
                    {
                        opacity: 1
                    }
                ]
            });
            gsap.set(".process_scroll_helper", { opacity: 0 });
            gsap.to(".process_scroll_helper", {
                repeat: -1,
                duration: 1,
                keyframes: [
                    {
                        y: 0,
                        opacity: 0,
                    },
                    {
                        y: 35,
                        opacity: 1,
                    },
                    {
                        y: 50,
                        opacity: 0,
                    },
                ]
            });

            gsap.set(".process_arrow", {
                opacity: 0,
            })

            gsap.to(".process_arrow", {
                repeat: -1,
                duration: 1.5,
                ease: "circ",
                keyframes: [
                    {
                        opacity: 1,
                        right: 15,
                        duration: 1.5,
                    },
                    {
                        opacity: 0,
                        right: 0,
                    },
                ]
            });

            const cursor = document.querySelector(".process_cursor");
            const cursorMedias = gsap.utils.toArray(".cursor_media");
            const cursor_containers = gsap.utils.toArray(".process_panel");

            gsap.set(cursor, {
                xPercent: -50,
                yPercent: -50,
                scale: 0
            });

            const setCursorX = gsap.quickTo(cursor, "x", {
                duration: 0.6,
                ease: "expo"
            });

            const setCursorY = gsap.quickTo(cursor, "y", {
                duration: 0.6,
                ease: "expo"
            });

            window.addEventListener("mousemove", (e) => {
                setCursorX(e.clientX);
                setCursorY(e.clientY);
            });

            const tl = gsap.timeline({
                paused: true
            });

            tl.to(cursor, {
                scale: 1,
                opacity: 1,
                duration: 0.5,
                ease: "expo.inOut"
            });

            cursor_containers.forEach((container, i) => {
                container.addEventListener("mouseover", () => {
                    cursorMedias[i].classList.add("active");
                    tl.play();
                });
            });

            cursor_containers.forEach((container, i) => {
                container.addEventListener("mouseout", () => {
                    tl.reverse();
                    cursorMedias[i].classList.remove("active");
                });
            });



        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <>
            <section className="process_section" style={{ marginBottom: "350px" }} ref={ref}>
                <div className="process_cursor">
                    <img src={light_bulb} className="cursor_media" />

                    <img src={check_mark} className="cursor_media" />

                    <img src={microphone} className="cursor_media" />
                </div>
                <div className='process_container'>
                    <div className='process_panel'>
                        <div className='process_grid'>
                            <h2>{t("present_an_idea")}</h2>
                            <p>
                                {t("present_an_idea_text")}
                            </p>
                            <div className='process_scroll_helper'>
                                <UilAngleDoubleDown size="150" />
                            </div>
                        </div>
                        <div className='process_arrow'><UilAngleRightB size="150" /></div>
                    </div>
                    <div className='process_panel'>
                        <div className='process_grid'>
                            <h2>{t("validate_the_problem")}</h2>
                            <p>
                                {t("validate_the_problem_text")}
                            </p>
                        </div>
                        <div className='process_arrow'><UilAngleRightB size="150" /></div>
                    </div>
                    <div className='process_panel'>
                        <div className='process_grid'>
                            {/* <img src={photo_1} className='process_img' /> */}
                            <h2>{t("pitch_your_startup")}</h2>
                            <p>
                                {t("pitch_your_startup_text")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProcessSection