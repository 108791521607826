import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger, SlowMo } from "gsap/all";
import photo_1 from '../../assets/img/photo_1.jpg'
import { useTranslation } from 'react-i18next';

function AwaitSection() {
    const ref = useRef();

    const { t } = useTranslation();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {


            // gsap.to("h2.first", {
            //     scrollTrigger: {
            //         trigger: "h2.first",
            //         // markers: true,
            //         start: "center center",
            //         end: "bottom+=400px top",
            //         scrub: true,
            //         pin: true,
            //         anticipatePin: true
            //     },
            //     keyframes: [
            //         {
            //             autoAlpha: 1,
            //         },
            //         {
            //             autoAlpha: 1,
            //             duration: 1,
            //         },
            //         {
            //             autoAlpha: 0,
            //         },
            //     ],
            // })


            // gsap.utils.toArray(".photo_container_wrapper").forEach(target => {
            //     gsap.to(target, {
            //         scrollTrigger: {
            //             trigger: target,
            //             // markers: true,
            //             start: "bottom bottom-=20vh",
            //             end: "center+=1000px top",
            //             pin: true,
            //             scrub: true,
            //             pinSpacing: "false",
            //             anticipatePin: 1,
            //         },
            //         translateY: "-40vh"
            //     });
            // });
            // gsap.utils.toArray(".photo_container").forEach(target => {
            //     gsap.to(target, {
            //         scrollTrigger: {
            //             trigger: target,
            //             // markers: true,
            //             start: "bottom bottom-=20%",
            //             end: "center+=1000px top",
            //             scrub: true,
            //             fastScrollEnd: true,
            //             invalidateOnRefresh: true,
            //             anticipatePin: 1,
            //         },
            //         keyframes: [
            //             {
            //                 autoAlpha: 1,
            //                 duration: 0.5
            //             },
            //             {
            //                 autoAlpha: 1,
            //                 duration: 1
            //             },
            //             {
            //                 autoAlpha: 1,
            //             },
            //             {
            //                 autoAlpha: 0,
            //                 duration: 1
            //             },
            //         ],
            //     });
            // });

        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <>
            <section style={{ marginTop: "70vh" }} ref={ref}>
                <div className='container'>
                    <h2
                        className='first'
                        style={{
                            fontSize: "72px",
                            textAlign: "center",
                        }}
                    >
                        {t("what_awaits_heading")}
                    </h2>
                </div>
                <div className="container photo_containers_container">
                    <div className='photo_container_wrapper'>
                        <div className='photo_container'>
                            <div className='photo_wrapper'>
                                <img src={photo_1} />
                            </div>
                            <div className='photo_caption'>
                                <h3>Build and develop an incredible team.</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='photo_container_wrapper'>
                        <div className='photo_container reverse'>
                            <div className='photo_caption'>
                                <h3>Meet successful startups with a stable business behind them.</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                            <div className='photo_wrapper'>
                                <img src={photo_1} />
                            </div>
                        </div>
                    </div>
                    <div className='photo_container_wrapper'>
                        <div className='photo_container'>
                            <div className='photo_wrapper'>
                                <img src={photo_1} />
                            </div>
                            <div className='photo_caption'>
                                <h3>Get mentoring support from professionals.</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='photo_container_wrapper'>
                        <div className='photo_container reverse'>
                            <div className='photo_caption'>
                                <h3>Learn to do successful customer validation.</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                            <div className='photo_wrapper'>
                                <img src={photo_1} />
                            </div>
                        </div>
                    </div>
                    {/* <div className='album_grid'>
                        <div>
                            <div className='album_caption'>
                                <h3>own startup</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                            <div className='album_caption'>
                                <h3>meet successful startupers</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                            <div className='album_caption'>
                                <h3>meet successful startupers</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                            <div className='album_caption'>
                                <h3>meet successful startupers</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                            <div className='album_caption'>
                                <h3>meet successful startupers</h3>
                                <p>
                                    In a remarkably short span of 54 hours, you are able to successfully establish and launch your very own startup. This accomplishment requires meticulous planning, unwavering dedication, and efficient execution.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className='album_container'>
                                <div className='album'>
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                </div>
                                <div className='album'>
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                </div>
                                <div className='album'>
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                    <img src={photo_1} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className='container'>
                    <h2
                        className='second'
                        style={{
                            marginBottom: "50px",
                            fontSize: "72px",
                            textAlign: "center",
                        }}
                    >
                        moreover...
                    </h2>
                </div>
                {/* <div className="fullscreen_ribbon reverse" style={{
                    transform: "rotate(10deg)",
                    marginTop: 50
                }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
                        <span key={`ribbon_1_${key}`} className={key % 2 === 0 ? 'bold' : ''}>startup weekend</span>
                    ))}
                </div> */}
                {/* <div className="fullscreen_ribbon reverse" style={{
                transform: "rotate(15deg)",
                marginTop: 50
            }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
                    <span key={`ribbon_1_${key}`} className={key % 2 === 0 ? 'bold' : ''}>startup weekend</span>
                ))}
            </div> */}
            </section >
        </>
    )
}

export default AwaitSection