import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import photo_1 from '../../assets/img/photo_1.jpg'
import company_1 from '../../assets/img/company_1.jpg'
import company_2 from '../../assets/img/company_2.jpg'
import company_3 from '../../assets/img/company_3.jpg'
import FsLightbox from 'fslightbox-react';
import Separator from '../common/Separator';

function AboutSection() {
    const ref = useRef();
    const [imageViewToggle, setImageViewToggle] = useState(false);

    const { t } = useTranslation();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.utils.toArray(".fullscreen_ribbon.normal").forEach(target => {
                gsap.to(target, {
                    scrollTrigger: {
                        trigger: target,
                        // markers: true,
                        scrub: 0.5,
                    },
                    keyframes: [
                        {
                            translateX: "-20%",
                            translateY: "30px",
                        },
                    ],
                    ease: "none",
                });
            });

            gsap.utils.toArray(".fullscreen_ribbon.reverse").forEach(target => {
                gsap.set(target, { translateX: "-10%" })
                gsap.to(target, {
                    scrollTrigger: {
                        trigger: target,
                        // markers: true,
                        scrub: 0.5,
                    },
                    keyframes: [
                        {
                            translateX: "0",
                            translateY: "30px",
                        },
                    ],
                    ease: "none",
                });
            });

            gsap.from(".counter", {
                scrollTrigger: {
                    trigger: ".counter",
                },
                textContent: 0,
                duration: 2,
                increment: 1,
                snap: { textContent: 1 },
            });

            gsap.utils.toArray(".brief_container").forEach(target => {
                gsap.set(target, { opacity: 0 })
                gsap.to(target, {
                    scrollTrigger: {
                        trigger: target,
                        start: "top bottom-=10%",
                        end: "top center+=10%",
                        // markers: true,
                        scrub: 0.5,
                    },
                    keyframes: [
                        {
                            opacity: 1
                        },
                    ],
                    ease: "none",
                });
            });
        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <>
            <section style={{ marginTop: "60vh" }} ref={ref}>
                <div className="container">
                    <div className='brief_container'>
                        <div className='brief_text'>
                            <h2>
                                {t("what_is_sw")}
                            </h2>
                            <div style={{ maxWidth: "650px" }}>
                                {/* <img src={connect_img} /> */}
                                <p>
                                    {t("what_is_sw_text")}
                                </p>
                            </div>
                        </div>
                        <div className='brief_other_container'>
                            <div className='brief_stat'>
                                <div className='brief_stat_item'>
                                    <h2><span className='counter'>90</span>+</h2>
                                    <h5>{t("stats.teams_formed")}</h5>
                                </div>
                                <div className='brief_stat_item'>
                                    <h2><span className='counter'>450</span>+</h2>
                                    <h5>{t("stats.participants")}</h5>
                                </div>
                                <div className='brief_stat_item'>
                                    <h2><span className='counter'>20</span>+</h2>
                                    <h5>{t("stats.partners")}</h5>
                                </div>
                                <div className='brief_stat_item'>
                                    <h2><span className='counter'>5</span></h2>
                                    <h5>{t("stats.programmes")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Separator /> */}
                    <div className='brief_container'>
                        <div className='brief_other_container'>
                            <div className='brief_gallery_container'>
                                <div className='brief_gallery'>
                                    <img src={company_2} alt="good company" />
                                    <img src={company_3} alt="good company" />
                                </div>
                            </div>
                        </div>
                        <div className='brief_text'>
                            <h2>
                                {t("work_with_mentors")}
                            </h2>
                            <div style={{ maxWidth: "650px" }}>
                                <p>
                                    {t("work_with_mentors_text")}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <Separator /> */}
                    <div className='brief_container reverse'>
                        <div className='brief_text'>
                            <h2>
                                {t("you_are_in_good_company")}
                            </h2>
                            <div style={{ maxWidth: "650px" }}>
                                <p>
                                    {t("you_are_in_good_company_text")}
                                </p>
                            </div>
                        </div>
                        <div className='brief_other_container'>
                            <div className='brief_gallery_container'>
                                <div className='brief_gallery'>
                                    <img src={company_2} alt="good company" />
                                    <img src={company_3} alt="good company" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fullscreen_ribbon normal" style={{
                    transform: "rotate(-5deg)",
                    top: -330
                }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
                        <span key={`ribbon_1_${key}`} className={key % 2 === 0 ? 'bold' : ''}>startup weekend</span>
                    ))}
                </div>
                <div className="fullscreen_ribbon reverse" style={{
                    transform: "rotate(8deg)",
                    top: -310
                }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
                        <span key={`ribbon_1_${key}`} className={key % 2 === 0 ? 'bold' : ''}>startup weekend</span>
                    ))}
                </div>


                {/* <div className='gallery_container'>
                        <div className='gallery_column'>
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                        </div>
                        <div className='gallery_column'>
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                        </div>
                        <div className='gallery_column'>
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                        </div>
                        <div className='gallery_column'>
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                            <img src={photo_1} alt="weekend" />
                        </div>
                    </div>
                    <FsLightbox 
                        toggler={imageViewToggle}
                        source
                    /> */}
            </section>
        </>
    )
}

export default AboutSection