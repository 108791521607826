import { useHelper } from '@react-three/drei';
import React, { useRef } from 'react'
import { DirectionalLightHelper } from 'three';

function DirectionalLight() {
    const dirLight = useRef();

    // useHelper(dirLight, DirectionalLightHelper, "red");

    return (
        <directionalLight position={[0, 0, 30]} intensity={1} ref={dirLight} />
    )
}

export default DirectionalLight