import "./App.css";
import "./services/i18n";
import BackgroundCanvas from "./components/BackgroundCanvas";
import HeaderSection from "./components/HeaderSection";
import MissionSection from "./components/MissionSection";
import LangSwitch from "./components/LangSwitch/LangSwitch";
import TransitionCircle from "./components/TransitionCircle";
import logo from "./assets/img/logo-dark.png";
import AboutSection from "./components/AboutSection";
import AwaitSection from "./components/AwaitSection";
import ProcessSection from "./components/ProcessSection";
import PinTextSection from "./components/PinTextSection/PinTextSection";
import UpcomingSection from "./components/UpcomingSection/UpcomingSection";
import FooterSection from "./components/FooterSection/FooterSection";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <div className="app">
      {/* <BackgroundCanvas /> */}
      <TransitionCircle />
      <div className="navbar_container">
        <nav>
          <div className="logo_wrapper">
            <img src={logo} alt={"techstars logo"} />
          </div>
          <LangSwitch />
        </nav>
      </div>
      <HeaderSection />
      <MissionSection />
      <AboutSection />
      <PinTextSection>
        <h2 className="fixed_heading" style={{ textAlign: "center" }}>
          {t("how_it_works")}
        </h2>
      </PinTextSection>
      <ProcessSection />
      <PinTextSection>
        <h2 style={{ marginBottom: 20 }}>
          {t("ready_for_the_journey_question")}
        </h2>
        <h4>{t("see_our_upcoming_events")}</h4>
      </PinTextSection>
      <UpcomingSection />
      {/* <AwaitSection /> */}
      <FooterSection />
    </div>
  );
}

export default App;
