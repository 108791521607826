import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import video from '../../assets/video/video.mp4'
import { useTranslation } from 'react-i18next';

function LangSwitch() {
    const ref = useRef();
    const { t, i18n } = useTranslation();
    const [supportedLangs, setSupportedLangs] = useState([]);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {

        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {
        setSupportedLangs(i18n.options.supportedLngs);
    }, []);

    const switchLanguage = () => {
        const currentLang = i18n.language;
        const currentIndex = supportedLangs.indexOf(currentLang);

        const langChange = new Event('langChange');
        window.dispatchEvent(langChange);

        setTimeout(() => {
            if (currentIndex < supportedLangs.length - 2) {
                i18n.changeLanguage(supportedLangs[currentIndex + 1]);
            }
            else {
                i18n.changeLanguage(supportedLangs[0]);
            }
            ScrollTrigger.refresh(true);
        }, 500);
    }

    return (
        <div className='langswitch_wrapper' ref={ref}>
            <div className='langswitch' onClick={switchLanguage}>
                {supportedLangs.slice(0, -1).map(l => (
                    <span className={`lang_element ${l === i18n.language ? 'active' : ''}`}>
                        {l}
                    </span>
                ))}
            </div>
        </div>
    )
}

export default LangSwitch