import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      mission_1: "We believe entrepreneurs can change the world.",
      mission_2: "We believe collaboration drives innovation.",
      mission_3: "We believe great ideas can come from anywhere.",
      stats: {
        teams_formed: "teams formed",
        participants: "participants",
        partners: "partners",
        programmes: "programmes",
      },
      what_is_sw: "What is Startup Weekend?",
      what_is_sw_text:
        "Learn how to think, work, and build like a startup in 54 thrilling hours. Over an action-packed three days, you’ll meet the very best mentors, investors, co-founders and sponsors to show you how to get more done faster -- and, maybe even start that Business.",
      work_with_mentors: "Work with experienced mentors",
      work_with_mentors_text:
        "Mentorship is one of the biggest takeaways from the Techstars experience. Expand your business and expand your network with access to a talented group of founders, investors, mentors, and industry leaders.",
      you_are_in_good_company: "You're in good company",
      you_are_in_good_company_text:
        "Since 2018, we’ve worked with startups across a range of verticals, from aerospace, to finance, to logistics, to retail, connecting them to a network of successful mentors and partners.",
      what_awaits_heading: "What awaits you at the hackathon?",
      how_it_works: "How it Works",
      present_an_idea: "Present an idea",
      present_an_idea_text:
        "We kick off the weekend with 1-minute pitches on back-of-the-napkin ideas you’ve been thinking on. Ideas are voted on and teams are formed. If you don't have an idea you'll be able to join up on an existing one.",
      validate_the_problem: "Validate the Problem",
      validate_the_problem_text:
        "Spend the rest of the weekend validating the problem, building a solution, and creating a business plan. We’ll teach you how to do all of this in our workshops.",
      pitch_your_startup: "Pitch Your Startup",
      pitch_your_startup_text:
        "Present your new startup with a Shark Tank style pitch to a panel of judges. The winners get a sweet prize package to help them grow their business.",
      ready_for_the_journey_question: "Ready for the journey?",
      see_our_upcoming_events: "See our upcoming events",
      our_upcoming_events: "Our upcoming events",
      follow_us_on_social_media: "Follow us on social media",
    },
  },
  uz: {
    translation: {
      mission_1:
        "Biz tadbirkorlar dunyoni o'zgartirishi mumkinligiga ishonamiz.",
      mission_2: "Biz hamkorlik innovatsiyalarga turtki bo'lishiga ishonamiz.",
      mission_3:
        "Biz ajoyib g'oyalar har qanday joydan kelishi mumkinligiga ishonamiz.",
      stats: {
        teams_formed: "jamoalar tuzildi",
        participants: "ishtirokchilar",
        partners: "hamkorlar",
        programmes: "dasturlar",
      },
      what_is_sw: "Startup Weekend bu nima?",
      what_is_sw_text:
        "54 hayajonli soat ichida startap kabi fikrlash, ishlash va qurishni o'rganing. Harakatlarga boy uch kun ichida siz eng yaxshi murabbiylar, investorlar, hammuassislar va homiylar bilan uchrashib, qanday qilib tezroq ko'proq ishni bajarishni ko'rsatasiz -- va, ehtimol, bu biznesni boshlashingiz mumkin.",
      work_with_mentors: "Mentorlar bilan ishlang",
      work_with_mentors_text:
        "Mentorlik Techstars tajribasidan olingan eng katta yutuqlardan biridir. Ta'sischilar, investorlar, murabbiylar va sanoat rahbarlarining iqtidorli guruhiga kirish imkoni bilan biznesingizni kengaytiring va tarmoqingizni kengaytiring.",
      you_are_in_good_company: "Siz yaxshi jamoadasiz",
      you_are_in_good_company_text:
        "2018-yildan beri biz startaplar bilan aerokosmikdan tortib moliya, logistika, chakana savdogacha turli sohalarda ishladik va ularni muvaffaqiyatli murabbiylar va hamkorlar tarmog‘iga uladik.",
      what_awaits_heading: "Hakatonda sizni nima kutmoqda?",
      how_it_works: "Bu qanday ishlaydi",
      present_an_idea: "Fikrni taqdim eting",
      present_an_idea_text:
        "'Weekend'ni siz o'ylab yurgan g'oyalar haqida 1 daqiqalik takliflar bilan boshlaymiz. G'oyalarga ovoz beriladi va jamoalar tuziladi. Agar sizda g'oya bo'lmasa, siz mavjud bo'lgan g'oyaga qo'shilishingiz mumkin",
      validate_the_problem: "Muammoni tasdiqlang",
      validate_the_problem_text:
        "Dam olish kunlarining qolgan qismini muammoni tekshirish, yechim topish va biznes-reja tuzishga sarflang. Bularning barchasini ustaxonalarimizda qanday qilishni sizga o'rgatamiz.",
      pitch_your_startup: "Startapni taklif qiling",
      pitch_your_startup_text:
        "Shark Tank uslubidagi yangi startapingizni hakamlar hay'atiga taqdim eting. G'oliblar o'z bizneslarini rivojlantirishga yordam berish uchun shirin sovg'alar paketiga ega bo'lishadi.",
      ready_for_the_journey_question: "Sayohatga tayyormisiz?",
      see_our_upcoming_events: "Bo'lajak tadbirlarimizni ko'ring",
      our_upcoming_events: "Bizning bo'lajak tadbirlar",
      follow_us_on_social_media: "Bizni ijtimoiy tarmoqlarda kuzatib boring",
    },
  },
  ru: {
    translation: {
      mission_1: "Мы верим, что предприниматели могут изменить мир.",
      mission_2: "Мы считаем, что сотрудничество способствует инновациям.",
      mission_3: "Мы верим, что отличные идеи могут прийти откуда угодно.",
      stats: {
        teams_formed: "команд сформировано",
        participants: "участников",
        partners: "партнеров",
        programmes: "программ",
      },
      partners: "партнеров",
      what_is_sw: "Что такое Startup Weekend?",
      what_is_sw_text:
        "Узнайте, как думать, работать и строить как стартап за 54 захватывающих часа. В течение трех насыщенных событиями дней вы встретитесь с самыми лучшими наставниками, инвесторами, соучредителями и спонсорами, которые покажут вам, как делать больше и быстрее — и, возможно, даже начать свой бизнес.",
      work_with_mentors: "Работайте с наставниками",
      work_with_mentors_text:
        "Наставничество — один из важнейших выводов из опыта Techstars. Расширяйте свой бизнес и расширяйте свою сеть благодаря доступу к талантливой группе основателей, инвесторов, наставников и лидеров отрасли.",
      you_are_in_good_company: "Вы в хорошей компании",
      you_are_in_good_company_text:
        "С 2018 года мы работаем со стартапами в самых разных отраслях: от аэрокосмической отрасли до финансов, логистики и розничной торговли, соединяя их с сетью успешных наставников и партнеров.",
      what_awaits_heading: "Что вас ждет на хакатоне?",
      how_it_works: "Как это работает",
      present_an_idea: "Представьте идею",
      present_an_idea_text:
        "Мы начинаем выходные с 1-минутных презентаций идей, о которых вы думали. Голосуются идеи и формируются команды. Если у вас нет идеи, вы сможете присоединиться к уже существующей.",
      validate_the_problem: "Подтвердите проблему",
      validate_the_problem_text:
        "Проведите остаток выходных, проверяя проблему, создавая решение и создавая бизнес-план. Всему этому мы научим вас на наших мастер-классах.",
      pitch_your_startup: "Презентуйте стартап",
      pitch_your_startup_text:
        "Представьте свой новый стартап жюри в стиле Shark Tank. Победители получают приятный призовой пакет, который поможет им развивать свой бизнес.",
      ready_for_the_journey_question: "Готовы к путешествию?",
      see_our_upcoming_events: "Посмотрите предстоящие мероприятия",
      our_upcoming_events: "Наши предстоящие мероприятия",
      follow_us_on_social_media: "Следите за нами в социальных сетях",
    },
  },
};

i18next
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources,
    debug: true,
    supportedLngs: ["en", "uz", "ru"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
