/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 Rocket.glb 
Author: lizlancaster (https://sketchfab.com/lizlancaster)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/rocket-9b9b64b138f24e4a908238c0b471930e
Title: Rocket 🚀
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';

export function Model(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF('/Rocket.glb');

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.x = Math.cos(t / 0.2) / 25
    ref.current.rotation.y = Math.sin(t / 1) / 5
    ref.current.position.y = (1 + Math.sin(t / 1.5)) / 5
  });

  return (
    <group {...props} dispose={null} ref={ref}>
      <group rotation={[-Math.PI / 2, 0.6, 0.8]} scale={0.936}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group position={[0, 0, 0.865]} rotation={[-Math.PI / 2, 0, -2.595]} scale={100}>
            <mesh geometry={nodes.Cylinder_Charcoal_0.geometry} material={materials.Charcoal} />
            <mesh geometry={nodes.Cylinder_Gloss_Red_0.geometry} material={materials.Gloss_Red} />
            <mesh geometry={nodes.Cylinder_Gloss_White_0.geometry} material={materials.Gloss_White} />
            <mesh geometry={nodes.Cylinder_Material001_0.geometry} material={materials['Material.001']} />
            <mesh geometry={nodes.Cylinder_White_Glow_0.geometry} material={materials.White_Glow} />
          </group>
          <mesh geometry={nodes.Icosphere_Flame_0.geometry} material={materials.material_0} position={[0, -145.482, 0]} rotation={[1.596, -0.006, 0.725]} scale={72.665} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/Rocket.glb')
