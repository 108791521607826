/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 rocketNew.glb 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';

export function Model(props) {
  const ref = useRef()
  const { nodes, materials, animations } = useGLTF('/rocketNew.glb')
  const { actions, mixer } = useAnimations(animations, ref);

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.x = Math.cos(t / 0.1) / 40
    ref.current.rotation.y = Math.sin(t / 1) / 5
    ref.current.position.y = (1 + Math.sin(t / 1.5)) / 5
  });

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="rocket4" position={[0, 0.3, 0.006]} rotation={[0.2, 0, -0.624]} scale={1.5}>
          <mesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials['Material #78']} />
          <mesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials['Material #85']} />
          <mesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials['Material #49']} />
          <mesh name="Mesh_3" geometry={nodes.Mesh_3.geometry} material={materials['Material #82']} />
          <mesh name="Mesh_4" geometry={nodes.Mesh_4.geometry} material={materials['Material #83']} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/rocketNew.glb')
