import React, { useEffect } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import { UilAngleRightB } from '@iconscout/react-unicons'

function PinTextSection(props) {
    const ref = useRef();
    const { children } = props;

    const { t } = useTranslation();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(".container.tall", {
                scrollTrigger: {
                    trigger: ".container.tall",
                    start: "center center",
                    end: "bottom top",
                    scrub: true,
                    pin: true,
                    anticipatePin: true,
                },
                keyframes: [
                    {
                        opacity: 1,
                    },
                    {
                        opacity: 0
                    }
                ]
            });
        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {

    }, []);

    return (
        <>
            <section ref={ref}>
                <div className="container tall centered">
                    {children}
                </div>
            </section>
        </>
    )
}

export default PinTextSection