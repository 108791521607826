import React, { Suspense, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { Canvas, useLoader } from "@react-three/fiber";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Model } from './Shoe'
import { Model as Bulb } from './Bulb'
import { Model as Rocket } from './Rocket'
import { Model as RocketNew } from './RocketNew'
import { OrbitControls, Stars, useHelper } from '@react-three/drei';
import { Bloom, ChromaticAberration, EffectComposer } from '@react-three/postprocessing';
import { DirectionalLightHelper } from 'three';
import DirectionalLight from './DirectionalLight';

function FooterCanvas() {
    const { ref, inView } = useInView();

    return (
        <div className="footer-bg-gl" ref={ref}>
            <Canvas frameloop={!inView ? 'never' : 'always'}>
                {/* <color attach="background" args={["#000"]} /> */}
                <ambientLight intensity={3} />
                {/* <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow /> */}
                <DirectionalLight />
                <Suspense>
                    <RocketNew />
                    {/* <Rocket /> */}
                </Suspense>
                {/* <boxBufferGeometry /> */}
                <Stars factor={2} count={500} />
                {/* <mesh>
                    <sphereGeometry args={[0.1]} />
                    <meshBasicMaterial color={[10, 1, 10]} intensity={100} toneMapped={false} />
                </mesh> */}
                <EffectComposer>
                    {/* <Bloom mipmapBlur luminanceThreshold={1} /> */}
                    <ChromaticAberration
                        // blendFunction={BlendFunction.NORMAL} // blend mode
                        offset={[0.0005, 0.001]} // color offset
                    />
                </EffectComposer>
                {/* <OrbitControls enableZoom={false} /> */}
            </Canvas>
        </div>
    )
}

export default FooterCanvas