import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { SlowMo } from "gsap/all";
import video from '../../assets/video/video.mp4'
import { ScrollTrigger } from 'gsap/all';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

function HeaderSection() {
    const ref = useRef();
    const gradientTimeline = useRef();

    const { t } = useTranslation();

    const [cityName, setCityName] = useState('');

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {

            gsap.to(".gradient_wrapper", {
                opacity: 1,
                duration: 2,
            });

            gsap.to(".title", {
                scrollTrigger: {
                    trigger: ".title",
                    start: "center center",
                    // markers: true,
                    scrub: 0.5,
                },
                y: -150,
                ease: "none",
            });

            const title_texts = gsap.utils.toArray(".title_text");

            title_texts.forEach((text, index) => {
                gsap.set(text, {
                    y: "100%"
                });
                gsap.to(text, {
                    y: 0,
                    delay: 0.5 + index * 0.25
                })
            })

            let videoToX = gsap.quickTo("video", "rotationX"),
                videoToY = gsap.quickTo("video", "rotationY");

            let gradientToX = gsap.quickTo(".gradient_wrapper", "x"),
                gradientToY = gsap.quickTo(".gradient_wrapper", "y");

            window.addEventListener("mousemove", (e) => {
                if (window.innerWidth > 767) {
                    var centerX = window.innerWidth * 0.5;
                    var centerY = window.innerHeight * 0.5;

                    const moveY = ((e.clientX - centerX) / centerX) * 12;
                    const moveX = -((e.clientY - centerY) / centerY) * 12;

                    videoToX(moveX);
                    videoToY(moveY);

                    gradientToX(e.clientX - 450);
                    gradientToY(e.clientY - 450);
                }
            });
        }, ref);

        return () => ctx.revert();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            typeWord("", "Tashkent");
        }, 1700);
        setTimeout(() => {
            typeWord("Tashkent", "", 30);
        }, 3500);
        setTimeout(() => {
            typeWord("", "Qarshi");
        }, 4000);

        function typeWord(initialWord = '', finalWord = '', speed) {
            setCityName(initialWord);
            setTimeout(() => {
                if (initialWord.length < finalWord.length) {
                    typeWord(finalWord.slice(0, initialWord.length + 1), finalWord, speed);
                }
                if (initialWord.length > 0 && initialWord.length > finalWord.length) {
                    typeWord(initialWord.substring(0, initialWord.length - 1), "", speed);
                }
            }, speed || 120);
        }
    }, []);

    return (
        <section className="header_section" ref={ref}>
            <div className="gradient_wrapper">
                <div className="gradient"></div>
            </div>

            <div className="video_container">
                <video src={video} autoPlay muted loop></video>
            </div>
            <div className="title_container">
                <div className="title">
                    <div className='title_text_wrapper'>
                        <div className='title_text'>techstars_</div>
                    </div>
                    <div className='title_text_wrapper'>
                        <div className='title_text'>Startup Weekend</div>
                    </div>
                    <div id="dynamic-title" data-text="['Uzbekistan', 'Tashkent']">
                        {cityName}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeaderSection