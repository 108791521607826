/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 Bulb.glb 
Author: AleixoAlonso (https://sketchfab.com/AleixoAlonso)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/incandescent-light-bulb-217c6b059c584243893b36ab76472ddd
Title: Incandescent Light Bulb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import { gsap } from 'gsap';
import { useEffect } from 'react';

export function Model(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF('/Bulb.glb');

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.x = Math.cos(t / 4) / 4
    ref.current.rotation.y = Math.sin(t / 4) / 4
    ref.current.position.y = (1 + Math.sin(t / 1.5)) / 5
  });

  return (
    <group {...props} ref={ref} dispose={null}>
      <spotLight intensity={20} angle={0.05} penumbra={1} position={[0, 25, 20]} color={'orange'} castShadow />
      <group rotation={[-Math.PI / 2, 0, 0]} scale={30} position={[0, -2, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Object_4.geometry} material={materials.LMP0002_Conductor} />
          {/* LMP0002_Metal */}
          <mesh geometry={nodes.Object_5.geometry} material={materials.LMP0002_GlassNo_Refraction} />
          <mesh geometry={nodes.Object_6.geometry} material={materials.LMP0002_Plexiglass} />
          <mesh geometry={nodes.Object_7.geometry} material={materials.LMP0002_Conductor} />
          <mesh geometry={nodes.Object_8.geometry} material={materials.LMP0002_Filament} />
          <mesh geometry={nodes.Object_9.geometry} material={materials.LMP0002_Emitter} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/Bulb.glb')
